import '@/commons';
import { Component, Vue } from 'vue-property-decorator';
import store from '@/store/index';
import { Debug } from '@/shared/utils/helpers';

import Header from '@/layout/header';
import Footer from '@/layout/footer';

const header = Header;
const footer = Footer;

interface Banner {
    code: string;
    titular: string;
    texto: string;
}

interface Servicio {
    code: string;
    url: string;
    titular: string;
    texto: string;
}

interface Casos {
    code: string;
    nombre: string;
    image: string;
    alt: string;
    title: string;
    color: string;
    items: any;
}

@Component({})
class IndexPage extends Vue {
    public slider: boolean = false;
    public contador: number = 1;
    public counter: number = 1;
    public progress: number = 1;
    public transicion: boolean = false;
    public cambio: boolean = false;
    public cambiar: boolean = false;
    public activar: number = 1;
    public elegido: number = 1;
    public banner: Banner | any = {};
    public servicio: Servicio | any = {};
    public caso: Casos | any = {};
    public interval1: any;
    public interval2: any;
    public case: number = 1;
    public casos: Casos[] = [
        {
            code: '1',
            nombre: 'Caso de éxito DHL',
            image: 'assets/images/index/caso-de-exito-dhl-stupendo-empresa-de-facturacion-electronica-en-quito-cuenca-guayaquil-ecuador.png',
            alt: 'Caso de éxito DHL de Stupendo,  empresa de facturación electrónica, documentos electrónicos en Quito, Cuenca, Guayaquil Ecuador ',
            title: 'Caso de éxito DHL de Stupendo,  empresa de facturación electrónica en Quito, Cuenca, Guayaquil Ecuador ',
            color: 'amarillo',
            items: [
                {
                    item: 'Emisión',
                    txt: 'Año 2013 - 720.000,00 documentos anual.',
                },
                {
                    item: 'Recepción',
                    txt: 'Año 2015 - 60.000,00 documentos anual.',
                },
                {
                    item: 'Recaudo y Conciliación',
                    txt: 'Año 2018 - 72.000,00 transacciones anual.',
                },
            ],
        },
        {
            code: '2',
            nombre: 'Caso de éxito Yanbal',
            image: 'assets/images/index/caso-de-exito-yambal-stupendo-empresa-de-facturacion-electronica-en-quito-cuenca-guayaquil-ecuador.png',
            alt: 'Caso de éxito Yambal de Stupendo,  empresa de facturación electrónica, documentos electrónicos en Quito, Cuenca, Guayaquil Ecuador ',
            title: 'Caso de éxito Yambal de Stupendo,  empresa de facturación electrónica en Quito, Cuenca, Guayaquil Ecuador ',
            color: 'gris',
            items: [
                {
                    item: 'Emisión',
                    txt: '2’331.491 documentos anuales',
                },
            ],
        },
        {
            code: '3',
            nombre: 'Caso de éxito Herbalife',
            image: 'assets/images/index/caso-de-exito-hervalife-stupendo-empresa-de-facturacion-electronica-en-quito-cuenca-guayaquil-ecuador.png',
            alt: 'Caso de éxito Herbalife de Stupendo,  empresa de facturación electrónica, documentos electrónicos en Quito, Cuenca, Guayaquil Ecuador ',
            title: 'Caso de éxito Herbalife de Stupendo,  empresa de facturación electrónica en Quito, Cuenca, Guayaquil Ecuador ',
            color: 'gris',
            items: [
                {
                    item: 'Emisión',
                    txt: '396.000 documentos anuales',
                },
                {
                    item: 'Recepción',
                    txt: '1300 documentos anuales',
                }
            ],
        },
    ];
    public lista: Banner[] = [
        {
            code: '1',
            titular: 'Sé estupendo',
            texto: 'Somos una plataforma SaaS Billing que agiliza procesos administrativos y financieros de cualquier giro de negocio. Reducimos costos de impresión y papeleo, acortamos tiempos y hacemos más rentable a tu empresa.',
        },
        {
            code: '2',
            titular: 'Seguridad',
            texto: 'Stupendo, es la única empresa con certificación de seguridad de la información ISO 27001 que garantiza un estricto nivel de invulnerabilidad de la información.',
        },
        {
            code: '3',
            titular: 'Fiabilidad',
            texto: 'Somos los únicos en ofrecer una promesa de compliance que previene la imposición de multas y pérdidas de negocio.',
        },
        {
            code: '4',
            titular: 'Automatización',
            texto: 'Basados en el concepto Internet of Things nuestra ruta está en concentrar todas las funciones que existen a nivel empresarial en una misma plataforma.',
        },
    ];
    public listado: Servicio[] = [
        {
            code: '1',
            url: '/sistemas-de-facturacion-electronica-en-quito-guayaquil-cuenca-ecuador.html',
            titular: 'Permite el envío de documentos electrónicos',
            texto: 'Facturas, notas de crédito, débito, retención y guía de remisión, desde cualquier dispositivo digital, brindando seguridad y control de los datos ingresados.',
        },
        {
            code: '2',
            url: '/empresa-de-recaudo-y-conciliacion-boton-de-pagos-en-linea-en-quito-guayaquil-cuenca-ecuador.html',
            titular: 'Permite cancelar tus facturas de manera rápida y segura',
            texto: 'A través de una amplia red de puntos de pago, el servicio de recaudos, permite a los clientes de nuestros aliados cancelar sus facturas. La plataforma facilita los procesos de pagos y permite a los clientes estar al tanto del estado de sus finanzas.',
        },
        {
            code: '3',
            url: '/firma-digital-para-poliza-electronica-en-quito-guayaquil-cuenca-ecuador.html',
            titular: 'Primera plataforma web que integra, organiza y sistematiza todos los procesos de tus pólizas',
            texto: 'Bajo nuestra política de agilizar y dar solución a las necesidades de nuestros aliados, el servicio de Póliza Electrónica se convierte en la primera y única plataforma web que integra, organiza y sistematiza todos los procesos en la obtención de una póliza de manera automática, eficiente y de acuerdo a los requerimientos legales establecidos.',
        },
        {
            code: '4',
            url: '/firma-electronica-para-documentos-electronicos-en-quito-guayaquil-cuenca-ecuador.html',
            titular: 'Permite el envío de documentos electrónicos desde cualquier dispositivo digital',
            texto: 'Quienes celebren un proceso contractual a través del sistema electrónico de Stupendo, obtendrán un data center especializado para el resguardo, entrega, firma y análisis de la información ingresada.',
        },
    ];
    public open(e: number) {
        if (e === this.contador) {
        } else {
            this.contador = e;
            this.traslade_slider(false);
            clearInterval(this.interval1);
            clearInterval(this.interval2);
            this.run_intervals();
            this.progress = 0;
        }
    }

    public mounted() {
        this.banner = {
            code: '1',
            titular: 'Sé estupendo',
            texto: 'Somos una plataforma SaaS Billing que agiliza procesos administrativos y financieros de cualquier giro de negocio. Reducimos costos de impresión y papeleo, acortamos tiempos y hacemos más rentable a tu empresa.',
        };

        this.servicio = {
            code: '1',
            url: '/sistemas-de-facturacion-electronica-en-quito-guayaquil-cuenca-ecuador.html',
            titular: 'Permite el envío de documentos electrónicos',
            texto: 'Facturas, notas de crédito, débito, retención y guía de remisión, desde cualquier dispositivo digital, brindando seguridad y control de los datos ingresados.',
        };
        this.caso = {
            code: '1',
            nombre: 'Caso de éxito DHL',
            image: 'assets/images/index/caso-de-exito-dhl-stupendo-empresa-de-facturacion-electronica-en-quito-cuenca-guayaquil-ecuador.png',
            alt: '',
            title: '',
            color: 'amarillo',
            items: [
                {
                    item: 'Emisión',
                    txt: 'Año 2013 - 720.000,00 documentos anual.'
                },
                {
                    item: 'Recepción',
                    txt: 'Año 2015 - 60.000,00 documentos anual.'
                },
                {
                    item: 'Recaudo y Conciliación',
                    txt: 'Año 2018 - 72.000,00 transacciones anual.'
                },
            ],
        };
        this.run_intervals();
    }

    public run_intervals() {
        this.interval1 = setInterval(() => {
            this.progress += 1;
        }, 50);
        this.interval2 = setInterval(() => {
            this.traslade_slider();
            this.progress = 0;
        }, 5000);
    }

    public traslade_slider(nxt: boolean = true) {
        if (nxt) {
            if (this.contador < this.lista.length) {
                this.contador += 1;
            } else {
                this.contador = 1;
            }
        }

        this.cambio = true;
        setTimeout(() => {
            this.cambio = false;
        }, 600);
        setTimeout(() => {
            const resultado = this.lista[this.contador - 1];
            this.banner = resultado;
        }, 500);
    }

    public change_ser(e: number) {
        if (this.elegido === e) {
        } else {
            this.elegido = e;
            this.cambiar = true;
            setTimeout(() => {
                this.slider = true;
            }, 550);
            setTimeout(() => {
                this.cambiar = false;
            }, 600);
            setTimeout(() => {
                const resultado = this.listado[e - 1];
                this.servicio = resultado;
            }, 500);
        }
    }

    public next_case() {
        this.transicion = true;
        const cases = this.casos.length;
        if (this.counter < cases) {
            this.counter += 1;
        } else {
            this.counter = 1;
        }
        setTimeout(() => {
            this.transicion = false;
        }, 550);
        setTimeout(() => {
            const resultado = this.casos[this.counter - 1];
            this.caso = resultado;
        }, 500);
    }

    public prev_case() {
        this.transicion = true;
        const cases = this.casos.length;
        if (this.counter === 1) {
            this.counter = cases;
        } else {
            this.counter -= 1;
        }
        setTimeout(() => {
            this.transicion = false;
        }, 550);
        setTimeout(() => {
            const resultado = this.casos[this.counter - 1];
            this.caso = resultado;
        }, 500);
    }

    public close_servicio() {
        this.slider = false;
    }
}
export default new IndexPage({
    el: '#index',
    store,
    delimiters: ['[{', '}]'],
});
